import { createWebHistory, createRouter } from 'vue-router';
import { authGuard } from './auth-guard';

const routes = [
	{path: '/:catchAll(.*)', redirect:{name:'Login'}},
	{
		path:'/login',
		name:'Login',
		component:()=>import('@/components/authentication/Layout.vue')
	},{
		path:'/admin',
		name:'PageLayout',
		beforeEnter:authGuard,
		component:()=>import('@/components/authentication/PageLayout.vue'),
		children:[
			{
				path:'/dashboard',
				name:'Dashboard',
				component:()=>import('@/components/Pages/dashboard.vue')
			},
			{
				path:'/buckets',
				name:'Buckets',
				component:()=>import('@/components/Pages/buckets.vue')
			},
			{
				path:'/bucketsObjects',
				name:'BucketsObjects',
				component:()=>import('@/components/Pages/bucketsObjects.vue')
			},
			{
				path:'/token',
				name:'Token',
				component:()=>import('@/components/Pages/token.vue')
			},
			{
				path:'/organization',
				name:'Organization',
				component:()=>import('@/components/Pages/organization.vue')
			},
			{
				path:'/user',
				name:'User',
				component:()=>import('@/components/Pages/user.vue')
			},
			{
				path:'/role',
				name:'Role',
				component:()=>import('@/components/Pages/role.vue')
			},
			{
				path:'/logout',
				name:'Logout',
				component:()=>import('@/components/authentication/logout.vue')
			},

		]
	}
];

const router = createRouter({
	history: createWebHistory(),
	hash:false,
	routes,
});

export default router;