import { createApp } from 'vue';
import App from './App.vue';
import { createVuestic } from 'vuestic-ui';
import 'vuestic-ui/css';
import router from './router/index';
import VueSidePanel from 'vue3-side-panel';
import 'vue3-side-panel/dist/vue3-side-panel.css';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import Toaster from "@meforma/vue-toaster";
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

const app=createApp(App);
app.use(createVuestic());
app.use(router);
app.use(VueCookies);
app.use(VueSidePanel);
app.use(LoadingPlugin);
app.use(Toaster,{position:"bottom",duration:4000});
app.config.globalProperties.axios=axios;
app.mount('#app');

axios.interceptors.request.use(
    request=>{
        if(app.$cookies.get('x-access-token')){
            request.headers['x-access-token']=app.$cookies.get('x-access-token')
        };
        return request;
    }
);

axios.interceptors.response.use(
    response=>{
        return response
    },
    res_err=>{
        if(res_err && res_err.response && (res_err.response.status==401||res_err.response.status==403)) {
            app.$cookies.remove('x-access-token');
            router.push({name:'Login'});
        }else{
            return Promise.reject(res_err)
        }
    }
);


